import React from 'react'

export default function TrackerQuotes({ quotes }) {
  return (
    <div className="tracker__quotes">
      {quotes.map(quote => (
        <p key={quote.id} className="ics__h4">“{quote.text}”</p>
      ))}
    </div>
  )
}
