import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import TrackerHero from '../components/tracker/TrackerHero'
import TrackerSection from '../components/tracker/TrackerSection'
import TrackerBlock from '../components/tracker/TrackerBlock'
import TrackerPoints from '../components/tracker/TrackerPoints'
import TrackerTechnicalPoints1 from '../components/tracker/TrackerTechnicalPoints1'
import TrackerMap from '../components/tracker/TrackerMap'
import TrackerCaseStudy from '../components/tracker/TrackerCaseStudy'
import TrackerCaseStudyIntro from '../components/tracker/TrackerCaseStudyIntro'
import TrackerCaseStudyChallenge from '../components/tracker/TrackerCaseStudyChallenge'
import TrackerCaseStudySolution from '../components/tracker/TrackerCaseStudySolution'
import TrackerQuotes from '../components/tracker/TrackerQuotes'
import CallToAction from '../components/tracker/CallToAction'

export const query = graphql`
  query GrantsTracker {
    dataJson {
        trackers {
        grants {
          id
          name
          title
          slug
          icon {
            publicURL
          }
          hero {
            title
            subtitle
            backgroundImage {
              childImageSharp {
                fluid(maxWidth: 800, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          block1 {
            title
            text
          }
          points {
            id
            text
          }
          block2 {
            title
            text
          }
          block3 {
            title
            text
          }
          techincalPoints1 {
            title
            points {
              id
              text
            }
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          techincalPoints2 {
            title
            points {
              id
              text
            }
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          map {
            publicURL
          }
          caseStudy {
            title
            clientCase1
            image {
              childImageSharp {
                fluid(maxWidth: 800, quality: 50) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            challenge
            solution
          }
          block4 {
            title
          }
          quotes {
            id
            text
          }
        }
      }
    }
  }
  `

export default function GrantsTracker({ data: { dataJson: { trackers: { grants } } } }) {
  return (
    <Layout title='Grants Tracker' url='/grants-tracker' page='grants-tracker'>
      <TrackerHero hero={grants.hero} title={grants.title} icon={grants.icon} />
      <TrackerSection>
        <TrackerBlock block1={grants.block1} />
        <TrackerPoints tracker={grants} />
        <TrackerBlock block1={grants.block2} block2={grants.block3} />
        <TrackerTechnicalPoints1 techincalPoints={grants.techincalPoints1} />
        <div className="spacer" />
        <TrackerTechnicalPoints1 techincalPoints={grants.techincalPoints2} />
        <TrackerMap tracker={grants} />
      </TrackerSection>
      <TrackerCaseStudy>
        <TrackerCaseStudyIntro tracker={grants} />
        <TrackerCaseStudyChallenge tracker={grants} />
        <TrackerCaseStudySolution tracker={grants} />
        <TrackerBlock block1={grants.block4} margin={true} />
        <TrackerQuotes quotes={grants.quotes} />
      </TrackerCaseStudy>
      <CallToAction />
    </Layout>
  )
}
